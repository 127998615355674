import React from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
// import logo from './logo.svg';
import Home from './components/Home.js'
import './App.css';

const App = () => {
  return (
    <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          {/* <Route path="/login/:id" component={Login} /> */}
          {/* <PrivateRoute path="/dashboard" component={Dashboard} /> */}
        </Switch>
    </Router>
  );
}

export default App;