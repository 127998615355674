// import { parsePhoneNumberFromString } from 'libphonenumber-js'

export const validateEmail = (email) => {
    console.log(email);
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
}

export const validatePhone = (phone) => {
    return /^\d{10,14}$/.test(phone);
}

// export const validatePhoneWithCountryCode = (phone) => {
//   const phoneNumber = parsePhoneNumberFromString(phone)
//   if (phoneNumber) {
//   	return phoneNumber.isValid()
//   }else{
//   	return 
//   }
  
// }  