import React, {useEffect} from 'react'
import $ from 'jquery'; 
import Fade from 'react-reveal/Fade';
import ScrollUpButton from "react-scroll-up-button";
import axios from 'axios'
import { validateEmail, validatePhone } from '../utils/validations'
import Constants from '../utils/constants'
// import { getToken } from '../utils/auth';
import FoundationLogo from '../assets/images/YoloHealth-foundation.png';
import LearnMoreArrow from '../assets/images/learn-more-arrow.png';
import ContactUsArrow from '../assets/images/contact-us-arrow.png';
import placeholder from '../assets/images/Vinay_Kumar.png';
import EmployeeImgMobile from '../assets/images/Vinay_Kumar.png';
import TwitterIcon from '../assets/images/twitter-icon.png';
import FBIcon from '../assets/images/fb-icon.png';
import LinkedinIcon from '../assets/images/linkedin-icon.png';
import InitiativeImg from '../assets/images/sanitation.jpg';
import Health from '../assets/images/healthcheckup.jpg';
import water from '../assets/images/water.jpg';
//import InitiativeImgMobile from '../assets/images/initiative-img-mobile.png';
import RightCheckCircle from '../assets/images/check-circle.png';
import MenuButton from '../assets/images/menu-button.png';
import MenuButtonClose from '../assets/images/close-menu-button.png';


const employeeDetails = [
    {name:"Vinayak Kumar", email:"chairman@yolohealthfoundation.org", level:"Chairperson",  src:placeholder},
    // {name:"Navin Prakash", email:"navin.prakash@yolohealthfoundation.org", level:"Vice President",  src:placeholder},
    // {name:"Vinayak Kumar", email:"chairman@yolohealthfoundation.org", level:"Chairperson",  src:placeholder}
]

const Home = () => {   
    const [underlineClass, setUnderlineClass] = React.useState('health-underline');
    const [activeNavbar, setActiveNavbar] = React.useState('home');
    const [error, setError] = React.useState(null);
    const [showSuccessModal, setShowSuccessModal] = React.useState(null);
    const [menubarModal, showMenubarModal] = React.useState(false);
    const [contactusFormData, setContactusFormData] = React.useState({
        name: "",
        email: "",
        phone: "",
        message: ""
    })

    const [width, setWidth] = React.useState(window.innerWidth);
    // const [height, setHeight] = React.useState(window.innerHeight);
    // const tabletSize =  1024;
    const mobileSize =  568;
    const updateWidthAndHeight = () => {
        setWidth(window.innerWidth);
        // setHeight(window.innerHeight);
    };
    React.useEffect(() => {
        window.addEventListener("resize", updateWidthAndHeight);
        return () => window.removeEventListener("resize", updateWidthAndHeight);
    });

    useEffect(() => {
        const handleScroll = () => {
            console.log(window.scrollY)
            if(window.scrollY < 600){
                setActiveNavbar('home')
            }else if (600 <= window.scrollY && window.scrollY < 1150){
                setActiveNavbar('intiatives')
            }else if (1150 <= window.scrollY && window.scrollY < 1675){
                setActiveNavbar('about')
            }else if (1675 <= window.scrollY && window.scrollY < 2500){
                setActiveNavbar('team')
            }else{
                setActiveNavbar('contact')
            }
        }
        document.addEventListener('scroll', ()=>handleScroll())
        return () => {
            document.removeEventListener('scroll', ()=>handleScroll())
        }
    }, [])

    const homeRef = React.createRef();
    const initiativeRef = React.createRef();
    const aboutRef = React.createRef();
    const teamRef = React.createRef();
    const contactRef = React.createRef();
    const scrollToSection = (scroll_to) => {
        setActiveNavbar(scroll_to)
        if (scroll_to==='home') {
          if(homeRef.current){
            homeRef.current.scrollIntoView({ 
               behavior: "smooth", 
               block: "nearest"
            })
          }
        }else if (scroll_to==='intiatives') {
          if(initiativeRef.current){
            initiativeRef.current.scrollIntoView({ 
               behavior: "smooth", 
               block: "start"
            })
          }
          
        }else if (scroll_to==='about') {
          if(aboutRef.current){
            aboutRef.current.scrollIntoView({ 
               behavior: "smooth", 
               block: "start"
            })
          }
        }else if (scroll_to==='team') {
            if(teamRef.current){
                teamRef.current.scrollIntoView({ 
                 behavior: "smooth", 
                 block: "start"
              })
            }
        }else{
            if(contactRef.current){
                contactRef.current.scrollIntoView({ 
                 behavior: "smooth", 
                 block: "start"
              })
            }
        }
    }

    const closeErrorBox = () => {
        setError(null);

        // maheshb here for testing pr merge
        console.log('log this')
    }

    const onContactusInputChange = (e, name) => {
        setError(null);
        let formData = Object.assign({}, contactusFormData);
        formData[name] = e.target.value;
        setContactusFormData(formData);
    }

    const year = (new Date()).getFullYear();

    const callFoundationContactusAPI = () => {
        if(contactusFormData.email === "" 
            | contactusFormData.name === "" 
            | contactusFormData.phone === "" 
            | contactusFormData.query === ""){
            setError("please fill all fields!");
            return;
        }
        if(!validateEmail(contactusFormData.email)){
            setError("please fill a valid email");
            return;
        }
        if(!validatePhone(contactusFormData.phone)){
            setError("please fill a valid phone");
            return;
        }
        setShowSuccessModal(null)
        const url = Constants.API_BASE_URL + "/homepage/yolo_foundation_enquiry";
        axios.post(url, contactusFormData)
        .then(function (response) {
            // setSuccessModal('success-modal')
            setContactusFormData({
                name: "",
                email: "",
                phone: "",
                message: ""
            })
            console.log(response);
            setShowSuccessModal(true)
            overflowHidden('hidden')
            setError(null);
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    function overflowHidden(overflow) {
        if (overflow === 'hidden') {  
           $('body').css("overflow","hidden");  
        }else{
          $('body').css("overflow","auto");  
        }
    }

    return (
        <div className="home-page-container">
            <Fade>
            <div className="navbar">
                <div className= "logo-image">
                    <img src={FoundationLogo} style={{"width":"140px"}} alt=""/>
                </div>
                {
                    width > mobileSize ?  
                    <div className= "navbar-list">
                        <div className={activeNavbar === 'home' ? "navbar-list-item navbar-list-active-item" : "navbar-list-item"} onClick={() => {scrollToSection('home')}}><b>Home</b></div>
                        <div className={activeNavbar === 'intiatives' ? "navbar-list-item navbar-list-active-item" : "navbar-list-item"} onClick={() => {scrollToSection('intiatives')}}><b>Initiatives</b></div>
                        <div className={activeNavbar === 'about' ? "navbar-list-item navbar-list-active-item" : "navbar-list-item"} onClick={() => {scrollToSection('about')}}><b>About</b></div>
                        <div className={activeNavbar === 'team' ? "navbar-list-item navbar-list-active-item" : "navbar-list-item"} onClick={() => {scrollToSection('team')}}><b>Team</b></div>
                        <div className={activeNavbar === 'contact' ? "navbar-list-item navbar-list-active-item" : "navbar-list-item"} onClick={() => {scrollToSection('contact')}} ><b>Contact</b></div>
                    </div>
                    :
                    <div className= "menu-button">
                        {menubarModal ?
                        <img src={MenuButtonClose} onClick={()=>{showMenubarModal(!menubarModal);overflowHidden('show')}} alt=""/>
                        :
                        <img src={MenuButton} onClick={()=>{showMenubarModal(!menubarModal);overflowHidden('hidden')}} alt=""/>
                        }
                    </div>
                }    
            </div>
            </Fade>
            
            <div className="main-content">
            <div className="overlay"></div>
                <div className="flex-column-center top-header" ref={homeRef}>
                    <div className="section-heading top-header-heading animate__animated animate__fadeInUp">Yolohealth Foundation</div>
                    <div className="top-header-subheading animate__animated animate__fadeInUp flex-container">We as a society, have been putting in a great amount of effort to advance the way healthcare systems function and it’s quite the journey our country has travelled and yet we’ve got a long way to go. Acknowledging the social, environmental, clinical and psychological drivers of health and connecting the community to essentials, we’re determined to provide the society with innovative plans to transform the healthcare system. </div>
                    <div className="flex-column-center learn-more-section animate__animated animate__fadeInUp" onClick={() => {scrollToSection('intiatives')}}>
                        <div className="learn-more">Learn More</div>
                        <img className="learn-more-icon" src={LearnMoreArrow} alt="" />
                    </div>
                </div>
          

                <div className="flex-column-center initiatives-section" ref={initiativeRef}>
                    <Fade bottom>
                    <div className="section-heading">
                        INITIATIVES
                    </div>
                    </Fade>
                    <Fade bottom>
                    <div className="blur-sub-heading">
                    Credible and reliable healthcare has been a cause of concern in our society and access to consistent healthcare has been a challenge. Our holistic approach is backed by high-tech and high-touch support, which is designed to bridge these gaps.
                    </div>
                    </Fade>
                    <Fade bottom>
                    <div className="initiatives-tabs-section">
                        <div className="initiatives-tabs">
                            <div className="initiatives-tab" onClick={()=>setUnderlineClass('health-underline')}>HEALTH</div>
                            <div className="initiatives-tab" onClick={()=>setUnderlineClass('sanitation-underline')}>SANITATION</div>
                            <div className="initiatives-tab" onClick={()=>setUnderlineClass('water-underline')}>WATER</div>
                        </div>
                        <div className={underlineClass + ' blue-underline'}></div>
                    </div>
                    </Fade>
                    <div className="initiatives-content">
                        
                     
                        {
                            underlineClass === 'health-underline' ? 
                            <>
                                <Fade bottom>
                                    <div className="initiatives-image">
                                        <img className="initiatives-img" src={width > mobileSize ? Health : Health } alt='' />
                                    </div>
                                </Fade>
                            
                            
                            <div className="initiatives-texts">
                              {/* <Fade bottom><div className="initiatives-content-heading">HEALTH ATM</div></Fade> */}
                                <Fade bottom>
                                    <p>With our Advanced Health ATMs, the society will experience the much awaited acceleration designed to detect various primary parameters, chronic diseases and provide care diagnostics for the same. </p>
                                <div className="initiatives-content-subheading">
                                    <img className="img-size" src={RightCheckCircle} alt=''/>
                                    <div className="initiatives-text">Designed with a high-resolution camera and touch-screen</div>
                                </div>
                                </Fade>
                                <Fade bottom>
                                <div className="initiatives-content-subheading">
                                    <img className="img-size" src={RightCheckCircle} alt=''/>
                                    <div className="initiatives-text">Capable to detect over 40 parameters in just a few minutes</div>
                                </div>
                                </Fade>
                                <Fade bottom>
                                <div className="initiatives-content-subheading">
                                    <img className="img-size" src={RightCheckCircle} alt=''/>
                                    <div className="initiatives-text">Monitors general body scans, cardiac check-ups, blood glucose and WBCs.Detects vital  signs of Diabetes, urine and haemoglobin.</div>
                                </div>
                                </Fade>
                                <div className="initiatives-contact-us">
                                    <Fade bottom><span className="contact-us-text" onClick={() => {scrollToSection('contact')}}>CONTACT US</span></Fade>
                                    <Fade bottom><img className="contact-us-arrow" src={ContactUsArrow} alt="" /></Fade>
                                </div>
                            </div>
                        </> 
                        : ''
                        }    
                        {
                            underlineClass === 'sanitation-underline' ?
                        <> 

                            <Fade bottom>
                            <div className="initiatives-image">
                                <img className="initiatives-img" src={width > mobileSize ? InitiativeImg : InitiativeImg } alt='' />
                            </div>
                        </Fade>
                    
                            
                            <div className="initiatives-texts">
                                {/* <Fade bottom><div className="initiatives-content-heading">SANITATION</div></Fade> */}
                                <Fade bottom>
                                    <p>One of the most fundamental things in life is indeed a task to achieve. We’re all for powering better health via clean sanitation facilities in the furthest corners of the country. </p>
                                <div className="initiatives-content-subheading">
                                    <img className="img-size" src={RightCheckCircle} alt=''/>
                                    <div className="initiatives-text">Focus on sustainable plans to build managed and safe sanitary stations</div>
                                </div>
                                </Fade>
                                <Fade bottom>
                                <div className="initiatives-content-subheading">
                                    <img className="img-size" src={RightCheckCircle} alt=''/>
                                    <div className="initiatives-text">Enhance the quality of prevailing sanitation facilities in the rural sectors of the country</div>
                                </div>
                                </Fade>
                               
                                <Fade bottom>
                                <div className="initiatives-contact-us">
                                    <span className="contact-us-text" onClick={() => {scrollToSection('contact')}}>CONTACT US</span>
                                    <img className="contact-us-arrow" src={ContactUsArrow} alt=''/>
                                </div>
                                </Fade>
                            </div>
                            </>
                        : ''
                        }
                        {
                            underlineClass === 'water-underline' ? 
                            <>
                            <Fade bottom>
                            <div className="initiatives-image">
                                <img className="initiatives-img" src={width > mobileSize ? water : water } alt='' />
                            </div>
                        </Fade>
                            
                            <div className="initiatives-texts">
                                {/* <Fade bottom><div className="initiatives-content-heading">WATER</div></Fade> */}
                                <Fade bottom>
                                    <p>Less than 50 percent of our country’s population has access to clean and safely managed drinking water, let alone for other consumption. </p>
                                <div className="initiatives-content-subheading">
                                    <img className="img-size" src={RightCheckCircle} alt=''/>
                                    <div className="initiatives-text">Determined to work in water divisions with the help of the Gov. of India</div>
                                </div>
                                </Fade>
                                <Fade bottom>
                                <div className="initiatives-content-subheading">
                                    <img className="img-size" src={RightCheckCircle} alt=''/>
                                    <div className="initiatives-text">Build plans to provide adequate and consistent access to clean water for basic hygiene and consumption</div>
                                </div>
                                </Fade>
                                <Fade bottom>
                                <div className="initiatives-content-subheading">
                                    <img className="img-size" src={RightCheckCircle} alt=''/>
                                    <div className="initiatives-text">Design technological support to ensure the flow of filtered and regenerated water, leverage harvested water during the monsoons and more </div>
                                </div>
                                </Fade>
                                <Fade bottom>
                                <div className="initiatives-contact-us">
                                    <span className="contact-us-text" onClick={() => {scrollToSection('contact')}}>CONTACT US</span>
                                    <img className="contact-us-arrow" src={ContactUsArrow} alt=''/>
                                </div>
                                </Fade>
                            </div>
                            </>
                        : ''
                        }
                    </div>
                </div>
                <div className="flex-column-center about-us-section" ref={aboutRef}>
                    <Fade bottom><div className="section-heading">ABOUT US</div></Fade>
                    <Fade bottom>
                        
                        <div className="blur-sub-heading about-us-subheading">We at Yolo Health Foundation, envision a world where consistent access to quality healthcare, water and sanitation are embraced by all. A world where every member of the society enjoys the fundamentals timely and at an affordable value. 
                                                                We are determined to design innovative healthcare solutions that bridges the gap between the sectors of the society. 
                                                                
                         </div></Fade>
                </div>
                <div className="flex-column-center our-team-section" ref={teamRef}>
                    <Fade bottom><div className="section-heading">OUR TEAM</div></Fade>
                    {/* <Fade bottom><div className="blur-sub-heading our-team-subheading"></div></Fade> */}
                    <Fade bottom>
                    <div className="employees-list">
                        {employeeDetails.map((employee,i)=>
                            <div className="employee-details" key={i}>
                                <img className='employee-image' src={width > mobileSize ? employee.src : EmployeeImgMobile} alt='' />
                                <div className="employee-name">{employee.name}</div>
                                <div className="employee-position">{employee.level}</div>
                                <div className="employee-email">{employee.email}</div>
                            </div>
                        )}
                    </div>
                    </Fade>
                </div>

                <div className="flex-column-center contact-us-section" ref={contactRef}>
                    <Fade bottom><div className="section-heading">CONTACT US</div></Fade>
                    <div className="flex-column-center contact-us-form">
                        <Fade bottom>
                        <input placeholder="Enter your full name" className="contact-us-field contact-us-input" 
                            onChange={(e) => {onContactusInputChange(e, 'name')}}
                            value={contactusFormData.name}
                        />
                        </Fade>
                        <Fade bottom>
                        <input placeholder="Enter your email address" className="contact-us-field contact-us-input" 
                            onChange={(e) => {onContactusInputChange(e, 'email')}}
                            value={contactusFormData.email}
                        />
                        </Fade>
                        <Fade bottom>
                        <input placeholder="Enter your Phone Number" className="contact-us-field contact-us-input" 
                            onChange={(e) => {onContactusInputChange(e, 'phone')}}
                            value={contactusFormData.phone}
                        />
                        </Fade>
                        <Fade bottom>
                        <textarea placeholder="Message" className="contact-us-field contact-us-textarea" 
                            onChange={(e) => {onContactusInputChange(e, 'message')}}
                            value={contactusFormData.message}
                        />
                        </Fade>
                        <Fade bottom><div className="contactus-form-btn" onClick={callFoundationContactusAPI}>Send</div></Fade>
                        { error ? 
                        <Fade>
                        <div className="error-box">
                            {error}
                            <i class="fa fa-times" title="edit" aria-hidden="true" onClick={closeErrorBox}></i>
                        </div>
                        </Fade>
                        : ''
                        }
                    </div>
                </div>

                <div className="footer">
                    <div className="footer-top-section">
                        <div className="footer-top-left-section">
                            <Fade bottom><div className="footer-heading">Yolohealth Foundation</div></Fade>
                            <Fade bottom><div className="footer-subheading-1">212, Woodrow Building,<br/>Veera Desai road, opposite Police chowki,<br/>Andheri West, Mumbai - 400053</div></Fade>
                        </div>
                        <div className="footer-top-right-section">
                            <Fade bottom><div className="footer-heading">Contact info</div></Fade>
                            <div className="contact-details">
                                <div className="contacts">
                                    <Fade bottom><span className="footer-subheading-2">Phone: <a href="tel:918010601584" className="footer-subheading-1">+91 8010601584</a></span></Fade>
                                    
                                </div>
                                <div className="contacts">
                                    <Fade bottom><span className="footer-subheading-2">Email: <a href="mailto:hello@yolohealthfoundation.org" className="footer-subheading-1">hello@yolohealthfoundation.org</a></span></Fade>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom-section">
                        <Fade bottom><div>© Yolohealth Foundation {year}</div></Fade>
                        <Fade bottom>
                        <div className="social-media-icons">
                            <img className="footer-icon" src={TwitterIcon} alt='' />
                            <img className="footer-icon" src={FBIcon} alt='' />
                            <img className="footer-icon" src={LinkedinIcon} alt='' />
                        </div>
                        </Fade>
                    </div>
                </div>
            </div>    
            <div className="scroll-top-btn"> <ScrollUpButton /> </div>
            { showSuccessModal ? 
                <div className="modal-wrapper" onClick={()=>{setShowSuccessModal(null);overflowHidden('show')}}>
                    <div className="flex-column-center success-modal">
                        <div className="modal-text">Thank you!</div>
                        <div className="modal-sub-text">Your message has been sent successfully. We will reach out to you shortly.</div>
                        <div className="modal-btn" onClick={()=>{setShowSuccessModal(null);overflowHidden('show')}}>OK</div>
                    </div>
                </div>
                : ''
            }
            { menubarModal ? 
                <div className="menu-bar" >
                    <div className="menubar-list">
                        <Fade bottom><div className={activeNavbar === 'home' ? "menubar-list-item menubar-list-active-item" : "menubar-list-item"} onClick={() => {scrollToSection('home');showMenubarModal(!menubarModal);overflowHidden('show')}}>Home</div></Fade>
                        <Fade bottom><div className={activeNavbar === 'intiatives' ? "menubar-list-item menubar-list-active-item" : "menubar-list-item"} onClick={() => {scrollToSection('intiatives');showMenubarModal(!menubarModal);overflowHidden('show')}}>Initiatives</div></Fade>
                        <Fade bottom><div className={activeNavbar === 'about' ? "menubar-list-item menubar-list-active-item" : "menubar-list-item"} onClick={() => {scrollToSection('about');showMenubarModal(!menubarModal);overflowHidden('show')}}>About</div></Fade>
                        <Fade bottom><div className={activeNavbar === 'team' ? "menubar-list-item menubar-list-active-item" : "menubar-list-item"} onClick={() => {scrollToSection('team');showMenubarModal(!menubarModal);overflowHidden('show')}}>Team</div></Fade>
                        <Fade bottom><div className={activeNavbar === 'contact' ? "menubar-list-item menubar-list-active-item" : "menubar-list-item"} onClick={() => {scrollToSection('contact');showMenubarModal(!menubarModal);overflowHidden('show')}} >Contact</div></Fade>
                    </div>
                </div>
                : ''
            }
        </div>
    )
}

export default Home
